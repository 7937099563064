.container {
  display: flex;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}
.innerContainer {
  width: 40%;
}

td {
  padding: 1vh 1.2vw;
}

.track:hover {
  cursor: pointer;
  background-color: rgb(230, 230, 230);
}

.tracksContainer {
  width: 40%;
  height: 50vh;
  overflow: auto;
  margin-top: 3vh;
  position: absolute;
}

.tracks {
  width: 100%;
  display: table;
}

p {
  margin: 0;
}

.trackTitle {
  font-weight: 600;
}

.refresh {
  margin-bottom: -5px;
  margin-left: 7px;
}

.refresh:hover {
  cursor: pointer;
}

.searchPrompt {
  font-size: 48px;
  font-family: 'AppleGaramond';
  letter-spacing: 1px;
  width: 100%;
  position: relative;
}

.searchSubtitle {
  font-family: 'Helvetica Neue';
  font-size: 1.4em;
  color: rgb(73, 71, 71);
  padding-top: 10px;
}

.searchContainer {
  padding-top: 2vh;
  width: 100%;
}

.search {
  border: none;
  border-bottom: 1px solid black;
  padding: 1vh 0;
  font-size: 1.5em;
  width: 100%;
}

.search:focus {
  outline: none;
}

.submit {
  font-size: 1em;
  padding: 1vh 2vw;
  outline: none;
  margin-left: 2vw;
}

td {
  display: table-cell;
}

table,
td,
tr {
  border: none;
  border-collapse: collapse;
  border-style: hidden;
}

@media only screen and (max-width: 768px) {
  .searchContainer {
    width: 100%;
  }
  .search {
    width: 70%;
    margin-bottom: 1vh;
  }
  .tracksContainer {
    width: 100%;
    height: 50vh;
  }
}
