.container {
  width: 60%;
}

.container h1 {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-size: 24px;
  color: var(--dark-grey);
}

.container h1:hover {
  cursor: pointer;
}

.back {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-bottom: -2px;
  margin-right: 5px;
}

.container button {
  font-size: 1em;
}

form {
  padding-top: 3vh;
}

.prompt {
  font-family: 'AppleGaramond';
  letter-spacing: 1px;
  font-size: 2.5em;
}

textarea {
  margin: 1.5vh 0;
  width: calc(100% - 48px);
  height: 50vh;
  font-size: 24px;
  padding: 12px 24px;
  border: 1px solid black;
  border-radius: 16px;
}

textarea:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
  .prompt {
    font-size: 1.75em;
  }
}
