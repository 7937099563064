.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back,
.back:visited {
  color: rgb(73, 71, 71);
  padding-top: 2vh;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 90%;
  }
}
