:root {
  --dark-grey: #828282;
}

a,
a:visited {
  color: var(--dark-grey);
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: auto;
}

.btn {
  background-color: #000;
  color: white;
  text-decoration: none;
  padding: 1vh 1.5vw;
  border-radius: 10px;
  border: none;
}

.btn:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s;
}

button,
input[type='text'],
input[type='button'],
input[type='submit'] {
  -webkit-appearance: none !important;
  -moz-appearance: none;
  appearance: none;
}

@media only screen and (max-width: 768px) {
  .btn {
    padding: 1vh 3vw;
  }
  .container {
    height: auto;
    min-height: 100vh;
  }
}
