.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h1 {
  margin: 0;
  font-family: Mazius, 'AppleGaramond';
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.03em;
}

.subtext {
  font-size: 48px;
  font-family: AppleGaramond;
  margin-bottom: 2vh;
  letter-spacing: 1px;
}
.subtext span {
  font-size: 1em !important;
}

.top {
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
}

.siteTitle {
  text-align: center;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  padding-top: 3vh;
}
