/* source: https://codepen.io/marcruecker/pen/mBJVBX */
.wrap {
  z-index: 2;
}

.toggle {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  z-index: 1000;
  font-size: 2.5em;
  font-family: 'Helvetica';
  font-weight: 600;
}

.albumCover {
  width: 330px;
  height: 330px;
}
.logo {
  height: 30px;
  margin-top: 15px;
}

.album {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  shape-outside: circle();
}

.album:hover {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .overlay,
  .vinyl {
    width: 300px;
    height: 300px;
  }
}
