.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  z-index: 1000;
}

.top,
.bottom {
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.link {
  text-decoration: underline;
  display: flex;
  flex-direction: row;
}

.link:hover {
  cursor: pointer;
}

.top {
  top: 0;
}
.bottom {
  bottom: 0;
}

.top > div,
.bottom > div,
.top > p,
.bottom > p {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
}

.modalContainer {
  padding: 12px 24px;
}

.aboutTitle {
  font-size: 48px;
  font-family: 'AppleGaramond', Georgia, 'Times New Roman', Times, serif;
  margin-bottom: 20px;
}

.aboutDescription {
  font-size: 24px;
  margin-bottom: 20px;
}
