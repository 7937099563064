.container div {
  min-width: none;
}

.trackName {
  text-align: center;
  font-family: 'Helvetica Neue';
  font-weight: 600;
  color: inherit;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  display: inline;
}

.trackName:visited {
  color: inherit;
}

.text {
  text-align: center;
  padding-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nameBanner {
  position: fixed;
  bottom: 10px;
  width: 100vw;
  font-size: 64px;
  color: inherit;
  z-index: 999;
  text-decoration: none;
}

.nameBanner:hover {
  cursor: pointer;
}

.text p {
  opacity: 55%;
}

.player,
.container {
  display: flex;
  align-items: center;
}

.player {
  width: 100%;
  justify-content: space-between;
  height: 100vh;
}

.body {
  font-family: 'AppleGaramond';
  position: fixed;
  overflow: auto;
  letter-spacing: 1px;
  display: flex;
}

.body p {
  overflow: auto;
}

.instructions,
.instructionsSubtitle {
  font-family: 'AppleGaramond';

  text-align: center;
}
.instructions {
  font-size: 32px;
  color: black !important;
}
.instructionsSubtitle {
  font-size: 24px;
  text-align: center;
  color: var(--dark-grey) !important;
}

.body:hover,
.instructions:hover,
.instructionsSubtitle:hover {
  cursor: pointer;
}

.container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  flex-direction: column;
}

.submit {
  margin-bottom: 2vh;
}

.back {
  margin-top: 2vh;
}

.ascii {
  width: 700px;
  height: 600px;
  object-fit: contain;
  position: fixed;
  opacity: 0.7;
  z-index: -1;
  mix-blend-mode: soft-light;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 2em;
  }
  .player {
    order: 1;
    flex-direction: column;
    text-align: center;
  }
  .container * {
    margin-bottom: 2vh;
  }
  .container {
    height: auto;
  }
  .body {
    height: auto;
    padding-left: 0;
    font-size: 1.75em;
  }
}
